import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-296229d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-margin-start" }
const _hoisted_2 = { slot: "label" }
const _hoisted_3 = { slot: "label" }
const _hoisted_4 = { slot: "label" }
const _hoisted_5 = { slot: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/tabs/users",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Create user")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.translate('Create a new user')), 1),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.desktopOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_toggle, {
                  checked: _ctx.isFacilityLogin,
                  onIonChange: _ctx.updateFacilityLogin,
                  "label-placement": "start",
                  justify: "space-between"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Facility login")), 1)
                  ]),
                  _: 1
                }, 8, ["checked", "onIonChange"])
              ]),
              _: 1
            }),
            (_ctx.isFacilityLogin)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.businessOutline
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_select, {
                        interface: "popover",
                        modelValue: _ctx.formData.facilityId,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.facilityId) = $event)),
                        onIonChange: _ctx.updateGroupName
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createTextVNode(_toDisplayString(_ctx.translate("Select facility")) + " ", 1),
                            _createVNode(_component_ion_text, { color: "danger" }, {
                              default: _withCtx(() => [
                                _createTextVNode("*")
                              ]),
                              _: 1
                            })
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.facilities ? _ctx.facilities : []), (facility) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: facility.facilityId,
                              value: facility.facilityId
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(facility.facilityName || facility.facilityId), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onIonChange"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "floating",
                        modelValue: _ctx.formData.groupName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.groupName) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _createTextVNode(_toDisplayString(_ctx.translate('Name')) + " ", 1),
                            _createVNode(_component_ion_text, { color: "danger" }, {
                              default: _withCtx(() => [
                                _createTextVNode("*")
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        label: _ctx.translate('Reset password email'),
                        "label-placement": "floating",
                        modelValue: _ctx.formData.emailAddress,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.emailAddress) = $event)),
                        type: "email"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        label: _ctx.translate('Facility contact number'),
                        "label-placement": "floating",
                        modelValue: _ctx.formData.contactNumber,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.contactNumber) = $event)),
                        type: "tel"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  })
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "floating",
                        modelValue: _ctx.formData.firstName,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.firstName) = $event)),
                        autofocus: ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createTextVNode(_toDisplayString(_ctx.translate('First name')) + " ", 1),
                            _createVNode(_component_ion_text, { color: "danger" }, {
                              default: _withCtx(() => [
                                _createTextVNode("*")
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "floating",
                        modelValue: _ctx.formData.lastName,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.lastName) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createTextVNode(_toDisplayString(_ctx.translate('Last name')) + " ", 1),
                            _createVNode(_component_ion_text, { color: "danger" }, {
                              default: _withCtx(() => [
                                _createTextVNode("*")
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { class: "ion-margin-top" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        label: _ctx.translate('Employee ID'),
                        "label-placement": "floating",
                        modelValue: _ctx.formData.externalId,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.externalId) = $event))
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        label: _ctx.translate('Email'),
                        "label-placement": "floating",
                        modelValue: _ctx.formData.emailAddress,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.emailAddress) = $event)),
                        type: "email"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        label: _ctx.translate('Phone number'),
                        "label-placement": "floating",
                        modelValue: _ctx.formData.contactNumber,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.contactNumber) = $event)),
                        type: "tel"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  })
                ], 64)),
            _createVNode(_component_ion_button, {
              class: "ion-margin-top",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.createUser()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Create User")) + " ", 1),
                _createVNode(_component_ion_icon, {
                  slot: "end",
                  icon: _ctx.arrowForwardOutline
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}