export const SN_USER = 'user'
export const USER_TOKEN_CHANGED = SN_USER + '/TOKEN_CHANGED'
export const USER_LIST_UPDATED = SN_USER + '/LIST_UPDATED'
export const USER_QUERY_UPDATED = SN_USER + '/QUERY_UPDATED'
export const USER_END_SESSION = SN_USER + '/END_SESSION'
export const USER_INFO_UPDATED = SN_USER + '/INFO_UPDATED'
export const USER_INSTANCE_URL_UPDATED = SN_USER + '/INSTANCE_URL_UPDATED' 
export const USER_PERMISSIONS_UPDATED = SN_USER + '/PERMISSIONS_UPDATED'
export const USER_SELECTED_USER_UPDATED = SN_USER + '/SELECTED_USER_UPDATED' 
export const USER_CLEAR_SELECTED_USER = SN_USER + '/CLEAR_SELECTED_USER'
export const USER_FAVORITE_PRODUCT_STORE_UPDATED = SN_USER + '/FAVORITE_PRODUCT_STORE_UPDATED'
export const USER_FAVORITE_SHOPIFY_SHOP_UPDATED = SN_USER + '/FAVORITE_SHOPIFY_SHOP_UPDATED'
