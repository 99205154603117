import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Reset password")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.translate('Password should be at least 5 characters long and contain at least one number, alphabet and special character.')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  ref: "password",
                  label: _ctx.translate('New password'),
                  onKeyup: _ctx.validatePassword,
                  onIonBlur: _ctx.markPasswordTouched,
                  placeholder: _ctx.translate('Enter password'),
                  name: "password",
                  modelValue: _ctx.newPassword,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPassword) = $event)),
                  id: "newPassword",
                  type: _ctx.showNewPassword ? 'text' : 'password',
                  "error-text": _ctx.translate('Password requirements not fulfilled.'),
                  autocomplete: "new-password"
                }, null, 8, ["label", "onKeyup", "onIonBlur", "placeholder", "modelValue", "type", "error-text"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  ref: "confirmPassword",
                  label: _ctx.translate('Verify password'),
                  onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateConfirmPassword())),
                  onIonBlur: _ctx.markConfirmPasswordTouched,
                  placeholder: _ctx.translate('Confirm password'),
                  name: "confirmPassword",
                  modelValue: _ctx.confirmPassword,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmPassword) = $event)),
                  id: "confirmPassword",
                  type: _ctx.showConfirmPassword ? 'text' : 'password',
                  "error-text": _ctx.translate('Passwords do not match.')
                }, null, 8, ["label", "onIonBlur", "placeholder", "modelValue", "type", "error-text"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.email?.length)
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 0,
              class: "ion-padding-top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.email), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_button, {
                  slot: "end",
                  fill: "clear",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sendResetPasswordEmail()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('Reset password email')) + " ", 1),
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.mailOutline,
                      slot: "end"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_fab, {
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              disabled: !_ctx.hasPermission(_ctx.Actions.APP_UPDT_PASSWORD) || _ctx.checkResetButtonStatus(),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.resetPassword()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.lockClosedOutline }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}