import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79ff22de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions ion-margin-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/tabs/users",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Create user")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    (_ctx.selectedUser.groupName)
                      ? (_openBlock(), _createBlock(_component_ion_card_title, { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedUser.groupName), 1)
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_card_title, { key: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedUser.firstName) + " " + _toDisplayString(_ctx.selectedUser.lastName), 1)
                          ]),
                          _: 1
                        })),
                    _createVNode(_component_ion_note, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.selectedUser.partyId), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.selectedUser.emailDetails?.email)
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: _ctx.mailOutline,
                          slot: "start"
                        }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedUser.emailDetails?.email), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.selectedUser.phoneNumberDetails?.contactNumber)
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: _ctx.callOutline,
                          slot: "start"
                        }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedUser.phoneNumberDetails?.contactNumber), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.selectedUser.externalId && _ctx.selectedUser.partyTypeId !== 'PARTY_GROUP')
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: _ctx.businessOutline,
                          slot: "start"
                        }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedUser.externalId), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.quickSetup()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Quick Setup")) + " ", 1),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    icon: _ctx.arrowForwardOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                color: "medium",
                fill: "outline",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmSetupManually()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Setup Manually")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}